.main_patrika_container {
  color: rgb(68, 66, 43);
  padding-top: 4rem;
}

.main_ptrk_img_container {
  background-color: rgba(0, 0, 0, 0.349);
  padding: 2rem 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 8000;
  flex-direction: column;
}
/* .hello_ptrk {
  content: "";
  background-color: black;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
} */

/* .main_ptrk_img_container::before {
  content: "";
  background-color: rgba(0, 0, 0, 0.849);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
} */
.ptrk_img_div {
  position: relative;
  width: 25rem;
}
.ptrk_img_div_src_img {
  width: 100%;
  height: 20rem;
}
.ptrk_btn_div {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.img_fuctionality_div {
  z-index: 1;
  background-color: black;
  width: 25rem;
}
.arrow_mr_right {
  margin-right: 1rem;
}

.icon_clr {
  color: white !important;
}

@media screen and (max-width: 600px) {
  .patrika_photo_section {
    flex-direction: column;
  }
  .main_patrika_container {
    padding-top: 2rem;
  }

  .individual_photo_div {
    width: 100%;
  }
  .individual_photo_img_src {
    object-fit: cover;
  }
}
