.mag_title_div {
  padding: 2rem 0;
}
.mag_btn_and_img_container {
  /* background-color: red; */
  padding: 1rem 0;
}

.mag_btn_div {
  margin: 1rem 0;
  width: 100%;
  height: 3rem;
}
.mag_img_main_div {
  width: 100% !important;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;

  /* gap: 1rem; */

  overflow: hidden;
  /* overflow-x: auto; */
  scroll-behavior: smooth;
  /* 
  scroll-snap-type: x mandatory;
  scroll-snap-type: mandatory;

  scroll-padding: 0px;
  scrollbar-width: 0; */
}
.mag_img_main_div::-webkit-scrollbar {
  display: none;
}
.mag_img_individual_div {
  padding: 0 1em;
}
.mag_img {
  width: 25.8vw !important;
  height: 17rem;
}
@media screen and (max-width: 648px) {
  .mag_img_individual_div {
    width: 100% !important;

    padding: 0 !important;
  }
  .mag_img {
    /* width: 328px !important; */
    object-fit: contain;
    width: 92vw !important;
    margin: 0 auto !important;
  }
}
