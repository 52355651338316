* {
  scroll-behavior: smooth;
}
.flex_row {
  display: flex !important;
  flex-direction: row !important;
}

.flex_column {
  display: flex !important;
  flex-direction: column !important;
}
.flex_column_0_center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.text_align_cent {
  text-align: center;
}
.mr_2_0 {
  margin: 2rem 0;
}
.width100 {
  width: 100%;
}
.color_white {
  color: white !important;
}
.color_title {
  color: rgb(37, 82, 167);
}
.color_bg {
  background-color: rgb(37, 82, 167);
}
.color_para {
  color: rgb(68, 66, 43);
}
.flex_column_center_center {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
}
.flex_between {
  display: flex !important;
  justify-content: space-between !important;
}
.flex_around {
  display: flex !important;
  justify-content: space-around !important;
}
.flex_around_center {
  display: flex !important;
  justify-content: space-around !important;
  align-items: center !important;
  text-align: center !important;
}

.flex_center_center {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.flex_btw_cntr {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}
.flex_none_cntr {
  display: flex;
  align-items: center;
}
