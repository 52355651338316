.slider_style {
  position: relative;
  /* width: 25rem; */
  display: flex;
  overflow: hidden;
  overflow-x: auto;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  scroll-behavior: smooth;
  justify-content: flex-start;

  scroll-snap-type: x mandatory;
  scroll-snap-type: mandatory;
  /* scroll-snap-points-x: repeat(100%); */
  scroll-padding: 0px;
  scrollbar-width: 0;
}
.slider_style::-webkit-scrollbar {
  display: none;
}

.indivisual_gallery_div {
  min-width: 100%;
  scroll-snap-align: start;
  scroll-snap-stop: normal;
  height: 22rem;
}
.indivisual_gallery_div_img {
  width: 100%;
  height: 100%;
}
.first_gallery_section {
  text-align: center;
  width: 100%;
  margin: 0 0.5rem;
  position: relative;
}

.gallery_li_a {
  text-decoration: none;
  list-style: none;
  letter-spacing: 0.4rem;
  color: rgb(68, 66, 43);
  text-transform: uppercase;
  font-weight: 600;
}
.gallery_li_a:hover {
  color: rgb(37 82 167);
}
.gallery_li {
  list-style: none;
  margin: 1rem 0;
  /* text-align: center; */
}
.btn_img_div_container {
  position: relative;
}
.main_btn_style {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;
}
.btn_div {
  height: 2rem !important;
  width: 2rem !important;
  cursor: pointer;
  color: black !important;
  border: 2px solid rgb(65, 64, 64) !important;
  border-radius: 50% !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn_div:hover {
  height: 2.3rem !important;
  width: 2.3rem !important;
  background-color: gray;
  border: none !important;

  color: whitesmoke !important;
}
.individual_btn_style {
  font-size: 1rem !important;
}
