.main_footer_bg {
  background-color: rgb(37 82 167);
  padding: 0.5rem 0;
}
.main_footer_container {
  width: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
}
.footer_logo_div {
  /* width: 100%; */
}
.footer_logo_div img {
  width: 6rem;
  border-radius: 50%;
}
.footer_text_div {
  color: white;
  padding: 0.5rem 0;
}
.footer_nav_div {
  width: 70%;
}
.footer_ul {
  list-style: none;
  display: flex;

  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0;
}
.footer_ul_a {
  color: white !important;
  font-weight: bold !important;
  font-size: 0.9rem !important;
  text-decoration: none !important;
  text-transform: uppercase !important;
}
.footer_ul_a:hover {
  color: black;
}
.footer_up_arrow {
  position: fixed;
  border: 1px solid whitesmoke;
  border-radius: 50%;
  padding: 0.25rem;

  right: 1rem;
  bottom: 5rem;
  color: whitesmoke !important;
}
.footer_up_arrow_icon {
  height: 1rem;
  width: 1rem;
}
.footer_hr_width {
  width: 100%;
}

.footer_Up_arrow_deactive {
  display: none;
}
.footer_up_arrow:hover {
  background-color: whitesmoke;
  color: black !important;
}

@media screen and (max-width: 760px) {
  .footer_ul {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
  }
}
@media screen and (max-width: 480px) {
  .footer_ul {
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }
  .footer_text_div {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}
