.patrika_photo_section {
  margin: 1rem 0;
  gap: 1rem;
}
.individual_photo_div {
  position: relative;
  width: 20rem;
  height: 10rem;
}
.individual_photo_div:hover .hover_photo_div {
  display: flex;
}
.individual_photo_img_src {
  width: 100%;
  height: 100%;
}
.hover_photo_div {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.295);
  top: 0;
  left: 0;
  /* display: flex; */
  display: none;
  justify-content: center;
  align-items: center;
}
.hover_text_plus {
  font-size: 3rem !important;
  color: white;
}
