.main_founder_container {
  background-color: rgb(218 236 253);
  /* width: 100%; */
}
.container_div_style {
  width: 100%;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center;
  text-align: center;
  gap: 2rem;
}
.individual_founder_section {
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  align-items: center !important;
  padding: 2rem 0 !important;
}
.founder_text_div {
  /* background-color: rgb(23 101 116); */
  background-color: rgb(37, 82, 167);
  margin: auto;
  color: white;
  border-radius: 0.3rem;
  /* background-color: aquamarine; */
  padding: 0.5rem 1rem;
}
.founder_img_div {
  position: relative;
  width: 20rem;
  margin: 1rem 0;
  transition: all 1s ease-in-out;
}
.founder_img_div:hover {
  background-color: black;
}
.blank_div_text_span {
  /* z-index: 1; */
  position: absolute;

  width: 100%;
  height: 100%;
  top: 45%;
  left: 0;
  color: white;
}

.founder_img_div:hover .founder_img_div_srcimg {
  transform: scale(0.5) translateY(-250px);
}

.founder_img_div_srcimg {
  position: relative;
  z-index: 5;

  width: 20rem;
  transition: all 0.5s ease-in-out;
}

@media screen and (max-width: 900px) {
  .container_div_style {
    flex-direction: column;
    gap: 0;
  }
}
