.main_event_container {
  margin-top: 2rem;
}
.event_title {
  width: 17% !important;
  border-radius: 0.3rem;

  position: absolute;
  z-index: 176;
}
.event_hr_line {
  width: 100%;
  position: relative;
}

.event_text_div {
  padding: 3rem 0;
  gap: 1rem;
}
@media screen and (max-width: 550px) {
  .event_title {
    width: 50% !important;
  }
}
