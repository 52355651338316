.main_header_container {
  background-image: url("https://image.freepik.com/free-vector/white-gray-geometric-pattern-background-vector_53876-136510.jpg");
  padding-bottom: 2rem;
}
.active {
  /* border-bottom: 2px solid red; */
  color: rgb(37, 82, 167) !important;
}
.main_header_container_deactive {
  display: none;
}
.main_header_container_active {
  display: block;
}
.main_div {
  min-height: 100px;
}

.nav_header_bg {
  background-color: rgb(37, 82, 167);
}
.nav_header {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  text-align: center !important;
  width: 100% !important;
  height: 100% !important;
}
.left_nav_header {
  display: flex;
  gap: 1.5rem;
}
.left_nav_header_span {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: white;
}

.left_nav_ul_li_a {
  color: white;
  text-decoration: none;
}
.right_nav_header {
  display: flex;
  gap: 1rem;
}
.nav_icons {
  font-size: 1.5rem;
  color: white;
}
.nav_icons:hover {
  cursor: pointer;
}

.new_nav_div {
  margin: 2rem 0;
}
.nav_logo {
  margin: 2.3rem 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.nav_logo img {
  border-radius: 50%;
  width: 9rem;
}

.new_nav_ul {
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0;
}
.new_nav_ul_li_a {
  color: rgb(68, 66, 43);
  font-weight: bold;
  font-size: 0.9rem;
  text-decoration: none;
  text-transform: uppercase;
}
.new_nav_ul_li_a:hover {
  color: rgb(37 82 167);
}
.hover_nav_hide {
  display: none;
  flex-direction: column;
  gap: 1rem;

  position: absolute;
  background-color: rgb(243, 241, 241);
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0px 0px 8px 1px rgb(153, 153, 153);
  z-index: 100;
}
.hover_aboutus:hover .hover_nav_hide {
  display: flex;
}

.popup_nav_menu {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: whitesmoke;
  align-items: center;
  position: sticky;
  top: 0;
  animation-name: popup;
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  z-index: 1000;
  /* transition: all 2s ease-in-out; */
}
@keyframes popup {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}
.popup_nav_menu_deactive {
  display: none !important;
}

.popup_nav_bg {
  background-color: whitesmoke !important;
}
.popup_nav_container_css {
  display: flex !important;
  width: 100% !important;
}

.popup_left_nav_menu img {
  padding-top: 0.2rem;
  width: 4rem !important;
  border-radius: 50% !important;
}
.popup_right_nav_bar {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.popup_nav_ul {
  list-style: none;
  font-size: 0.9rem;
  width: 90%;
  display: flex;
  justify-content: space-around;
  width: suto;
  padding-left: 0;
}
.popup_nav_ul_li_a {
  text-decoration: none;
  text-transform: uppercase;
  color: rgb(68, 66, 43);
  font-weight: bold;
}
.drawer_nav_img {
  padding: 0.5rem 0;
  width: 5rem !important;
  border-radius: 50%;
}

.main_drawer {
  background-color: whitesmoke;
  display: none;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
}
.drawer_menu {
  position: absolute;
  left: 0;
  top: auto;
}

.drawer_menu_style {
  margin: 2rem 0 !important;
  color: rgb(54, 53, 53) !important;
  font-weight: bold !important;
  font-size: 1rem !important;
  line-height: 3rem !important;
}
.drawer_menu_text {
  text-transform: uppercase !important;
  font-weight: bold !important;
  font-size: 2rem !important;
}
.burger {
  color: rgb(54, 53, 53) !important;
}
@media screen and (max-width: 1000px) {
  .main_header_container {
    display: none;
  }
  .main_drawer {
    display: flex;
  }
  .main_div {
    min-height: 0;
  }
  .popup_nav_menu {
    display: none !important;
  }
}
