.main_gallery_container {
  margin: 2rem 0;
  display: flex;
  align-items: center;
  width: 100%;
}

/* .img_btn_div {
  position: relative;
  height: 14rem;
  width: 20rem;
} */
/* .img_btn_div img {
  
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  position: absolute;
} */

@media screen and (max-width: 900px) {
  .main_gallery_container {
    flex-direction: column;
  }
  .first_gallery_section {
    margin: 0;
  }
  .slider_style {
    flex-direction: column;
  }
}
