.contact_bg_clr {
  background-color: rgb(218, 218, 218);

  width: 100%;
}
.main_contact_address_container {
  padding: 2rem;

  width: 100% !important;
}
.contact_address {
  padding: 2rem;
  width: 30%;
  gap: 1.6rem;
  border-radius: 1rem 0 0 1rem;
}

.followus_icons {
  padding-top: 6rem;
}

/* input contact styles */

.contact_container {
  padding-top: 6rem;
  width: 100%;
  background-color: white;
  border-radius: 0 1rem 1rem 0;
}
.contact_title {
  width: 60%;
}
.contact_input_field {
  padding: 1rem 0;
  gap: 1rem;
  width: 60%;
}
.formik_form_div {
  padding-top: 0.2rem;
  align-items: flex-start;
  gap: 1rem;
}

.bg_white {
  background-color: white;
  width: 100%;
  border-radius: 0.3rem !important;
}

.form_textfield {
  padding: 0.5rem !important;
}

.contact_btn_div {
  padding-bottom: 1rem;
}
.mui_btn {
  color: white !important;
  font-weight: normal !important;
}
.mui_btn:hover {
  background: white !important;
  color: rgb(46, 46, 46) !important;
  font-weight: bold !important;
}

/* common css for contact us file */

.new_bg_clr {
  background-image: linear-gradient(
    to bottom right,
    rgb(37, 82, 167),
    blueviolet
  ) !important;
}
.new_text_1_clr {
  color: rgb(221, 219, 219) !important;
}
.new_text_2_clr {
  color: white !important;
}
.padding_1_0 {
  padding: 0.5rem 0;
}
.gap_1 {
  gap: 1rem;
}
@media screen and (max-width: 640px) {
  .main_contact_address_container {
    flex-direction: column !important;
    gap: 1rem;
  }
  .contact_address {
    width: auto;

    border-radius: 0.6rem;
  }
  .followus_icons {
    padding-top: 2rem;
  }

  .contact_container {
    border-radius: 0.6rem;
    padding: 1.5rem 0;
  }
  .contact_input_field {
    width: 90%;
  }
  .contact_title {
    text-align: center;
  }
  .mui_btn {
    width: 100%;
  }
}
