.book_title_div {
  padding-top: 2rem;
  gap: 1rem;
}
.book_table_div {
  padding: 2rem 0;
}
.book_text {
}
.book_name {
  width: 100% !important;
}
.book_table {
  width: 60% !important;
}
@media screen and (max-width: 640px) {
  .book_table {
    width: 100% !important;
  }
  .book_name {
    padding-top: 1rem;
  }
}
